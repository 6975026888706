import * as React from 'react';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import styled from 'styled-components';

import { PAGES } from '../config/constants';
import { withQuery } from '../utils/withQuery';
import Layout from '../components/layout';
import Heading from '../components/heading';
import Contact from '../components/contact';

const Content = styled.div`
  margin-bottom: 3rem;
  text-align: justify;
`;

const About = ({ content }) => (
  <Layout
    pageTitle="About"
    pageDescription="About poet and visual artist Linda Black, author of poetry collections including Slant and The Son of a Shoemaker and editor of Long Poem Magazine"
    breadcrumbTrail={[PAGES.ABOUT]}
  >
    <Heading>About</Heading>
    <Content>{content}</Content>
    <Contact/>
  </Layout>
);

export const query = graphql`
  query About {
    contentfulPage(contentful_id: { eq: "3R9iaXAnMc3G0KSotVKUGH" }) {
      title
      content {
        raw
      }
    }
  }
`;

const mapDataToProps = ({ contentfulPage: { content } }) => ({
  content: documentToReactComponents(JSON.parse(content.raw)),
});

export default withQuery(mapDataToProps)(About);
